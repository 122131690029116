<template>
  <div class="center">
    <side-bar
      ref="history"
      @newPrompt="newPrompt"
      @setDetail="setDetail"
      @refreshHistory="refreshHistoryMap"
      :role="role"
    />
    <div class="p-center">
      <div class="p-content">
        <div class="radio-group" style="transform: translateY(0px)">
          <div
            @click="setModelType(3)"
            :class="[
              'radio-button',
              modelType === 3 ? 'radio-button-11' : '',
              'radio-label',
            ]"
          >
            gpt3.5
          </div>
          <!-- <div
            @click="setModelType('4o')"
            :class="[
              'radio-button-31',
              'radio-label',
              modelType === '4o' ? 'radio-button-32' : '',
              modelType === '4o' ? 'radio-button-33' : '',
            ]"
          >
            <span :class="modelType === '4o' ? 'radio-button-text' : ''"
              >gpt4o</span
            >
          </div> -->
          <div
            @click="setModelType('4oMini')"
            :class="[
              'radio-button-41',
              'radio-label',
              modelType === '4oMini' ? 'radio-button-42' : '',
              modelType === '4oMini' ? 'radio-button-43' : '',
            ]"
          >
            <span :class="modelType === '4oMini' ? 'radio-button-text' : ''"
              >gpt4o-mini</span
            >
          </div>
          <!-- <div
            @click="setModelType('4t')"
            :class="[
              'radio-button-51',
              'radio-label',
              modelType === '4t' ? 'radio-button-52' : '',
              modelType === '4t' ? 'radio-button-53' : '',
            ]"
          >
            <span :class="modelType === '4t' ? 'radio-button-text' : ''"
              >gpt4-turbo</span
            >
          </div> -->
          <div
            @click="setModelType(4)"
            :class="[
              'radio-button-21',
              'radio-label',
              modelType === 4 ? 'radio-button-22' : '',
              modelType === 4 ? 'radio-button-23' : '',
            ]"
          >
            <img
              class="radio-button-vip"
              src="https://nlp-eb.cdn.bcebos.com/static/eb/asset/vip-active-new.edfe8b12.png"
              alt="vip"
            /><span :class="modelType === 4 ? 'radio-button-text' : ''"
              >gpt4</span
            >
          </div>
        </div>

        <div class="main">
          <div class="m-center" ref="scrollContainer">
            <div class="m-block" v-if="talkList.length == 0">
              <div class="m-logo" :class="'gpt' + modelType">
                <ai></ai>
              </div>
              <div class="m-content help">
                <div class="text-top">
                  <strong>你好，我是chat</strong
                  ><span
                    >作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑。<br />想知道我还能做什么？<a
                      id="wxGuideLink"
                      @click="promptUserToBookmark()"
                      >点这里收AI灵感坊官网地址，下次使用更高效哦～！</a
                    ></span
                  >
                </div>
                <div class="text-action">
                  <div class="text-tip">
                    <span>你可以试着问我：</span>
                    <div class="text-right">
                      <span class="text-btn" @click="refreshTopic"
                        ><span
                          :class="[
                            'text-refresh',
                            isRefreshing ? 'refresh_anim' : '',
                          ]"
                        >
                          <refresh></refresh> </span
                        >换一换</span
                      ><span class="text-split"></span>
                      <!-- <span class="text-more"
                        >更多
                        <div class="text-btn-more">
                          <more></more></div
                      ></span> -->
                    </div>
                  </div>
                  <div class="topic-panel">
                    <div
                      v-for="(topic, index) in topicList"
                      :key="index"
                      class="topic-card"
                      @click="sendTopic(topic)"
                    >
                      <div class="topic-content">
                        <div class="topic-icon">
                          <img
                            class="topic-icon-img"
                            src="http://eb118-file.cdn.bcebos.com/aisuda/2023-10/CEI6sAF1fjdBGJRD9KHGPk.png"
                            width="21"
                            height="21"
                            alt=""
                          /><span class="topic-icon-text">{{
                            topic.title
                          }}</span>
                        </div>
                        <span class="topic-center">{{ topic.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template v-else>
              <div
                v-for="(item, index) in talkList"
                :key="index"
                :id="`msg-${item.id}`"
                class="m-block"
              >
                <div
                  v-if="item.type == 0"
                  class="m-logo"
                  :class="'gpt' + modelType"
                >
                  <i
                    class="el-icon-loading"
                    style="color: #fff; font-size: 20px; font-weight: bold"
                    v-if="index == talkList.length - 1 && isInput"
                  ></i>
                  <ai v-else></ai>
                </div>
                <div v-else class="m-logo-img">
                  <img :src="currentUser.avatarUrl" />
                </div>
                <div class="m-content ai" v-if="item.type == 0">
                  <div class="content" @click="handleClick">
                    <span
                      v-html="getMdiText(item.content).replace(/\n/gm, '<br>')"
                      v-if="item.content"
                    ></span>
                    <span v-if="isInput" class="blink-input">|</span>
                  </div>

                  <div v-if="item.content" class="m-action">
                    <el-link
                      v-if="index == talkList.length - 1 && isInput == false"
                      :underline="false"
                      @click="reCreate"
                      type="primary"
                      >重新生成</el-link
                    >
                    <el-link
                      v-if="index == talkList.length - 1 && isInput == false"
                      :underline="false"
                      @click="continueWrite"
                      type="primary"
                      >继续输出</el-link
                    >
                    <el-link
                      v-else-if="index == talkList.length - 1 && isInput"
                      :underline="false"
                      @click="stopCreate"
                      type="primary"
                      >停止输出</el-link
                    >
                    <div v-else></div>
                    <el-link
                      :underline="false"
                      type="primary"
                      v-if="index != talkList.length - 1 || isInput == false"
                      @click="copyText(item.content)"
                      icon="el-icon-copy-document"
                      >复制</el-link
                    >
                  </div>
                </div>
                <div v-else class="m-content user">
                  <div class="content">
                    {{ item.content }}

                    <el-link
                      :underline="false"
                      type="primary"
                      @click="copyText(item.content)"
                      icon="el-icon-copy-document"
                    ></el-link>
                    <el-link
                      :underline="false"
                      type="danger"
                      style="margin-left: 3px"
                      v-if="isInput == false"
                      @click="deleteText(index)"
                      icon="el-icon-delete"
                    ></el-link>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="m-bottom">
            <div class="tip" v-if="modelType == 4">
              每次消耗 {{ apiSetting["002"] }}积分
            </div>
            <div class="tip" v-if="modelType == '4o'">
              每次消耗 {{ apiSetting["002-4o"] }}积分
            </div>
            <div class="tip" v-if="modelType == '4t'">
              每次消耗 {{ apiSetting["002-4t"] }}积分
            </div>
            <el-input
              type="textarea"
              :rows="4"
              size="medium"
              style="font-size: 16px"
              placeholder="请输入问题"
              @keyup.enter.native="sendMessage"
              v-model="content"
            >
            </el-input>
            <span :disabled="isInput" class="commit" @click="sendMessage">
              <commit></commit>
            </span>
          </div>
          <!-- <div class="m-bottom" v-if="modelType == 4">
            消耗 {{ apiSetting["002"] }}积分
          </div> -->
        </div>
      </div>
    </div>
    <LoginPopup ref="loginPopup" />
  </div>
</template>

<script>
import SideBar from "./Siderbar/index.vue";
import ai from "./icon/ai.vue";
import commit from "./icon/commit.vue";
import refresh from "./icon/refresh.vue";
import { getToken } from "@/service/token";
import uuid from "@/utils/uuid";
import chatSampleService from "@/service/api/gpt/chatSampleService";
import { shuffleArray } from "@/utils/utils";
import MarkdownIt from "markdown-it";
import mdKatex from "@traptitech/markdown-it-katex";
import hljs from "highlight.js";
// import Clipboard from "clipboard";

export default {
  name: "layout",
  components: {
    SideBar,
    ai,
    refresh,
    commit,
  },
  data() {
    return {
      socket: null,
      modelType: 3,
      role: "user",
      content: "",
      isInput: false,
      message: "",
      uuid: null,
      talkList: [],
      topicList: [],
      historyId: null,
      historyMap: null,
      isRefreshing: false,
      mdi: null,
      htmlStr: "",
    };
  },
  created() {
    this.refreshTopic();
    this.historyId = uuid();
    this.uuid = localStorage.getItem("uuid");
    if (this.uuid == null || this.uuid == "" || this.uuid == "null") {
      this.uuid = uuid();
      localStorage.setItem("uuid", this.uuid);
    }
    let highlightBlock = this.highlightBlock;
    this.mdi = new MarkdownIt({
      linkify: false,
      highlight(code, language) {
        const validLang = !!(language && hljs.getLanguage(language));
        if (validLang) {
          const lang = language ?? "";
          return highlightBlock(
            code,
            hljs.highlight(lang, code, true).value,
            lang
          );
        }
        return highlightBlock(code, hljs.highlightAuto(code).value, "");
      },
    });
    this.mdi.use(mdKatex, {
      blockClass: "katexmath-block rounded-md p-[10px]",
      errorColor: " #cc0000",
    });
  },
  mounted() {
    if (getToken()) {
      this.createSocket();
    }
    this.refreshHistoryMap();
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    apiSetting() {
      return this.$store.state.login.apiSetting;
    },
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min); // 向上取整
      max = Math.floor(max); // 向下取整
      return Math.floor(Math.random() * (max - min + 1)) + min; // 生成随机整数
    },
    highlightBlock(code, str, lang) {
      const randomNumber = this.getRandomInt(1, 10);
      var id = new Date().getTime() + randomNumber;
      return `<div class="pre-code-box"><div class="pre-code-header"><span class="code-block-header__lang">${lang}</span><button id="btn${id}"  class="copy-btn code-btn code-block-header__copy">复制代码</button></div><div class="pre-code"><code id="code${id}" class="hljs code-block-body ${lang}">${str}</code></div></div>`;
    },
    containsCodeSnippet(str) {
      // 匹配单行代码（反引号包围）或多行代码（三个反引号包围）
      const codePattern = /(```[\s\S]*?```|`[^`]+`)/g;
      return codePattern.test(str);
    },
    getMdiText(value) {
      if (this.containsCodeSnippet(value)) {
        return this.mdi.render(value);
      } else {
        return value;
      }
    },
    handleClick(event) {
      if (event.target.classList.contains("copy-btn")) {
        let codeId = event.target.id.replace("btn", "code");
        let code = document.getElementById(codeId).innerText;
        this.copyText(code);
      }
    },
    promptUserToBookmark() {
      if (window.sidebar && window.sidebar.addPanel) {
        // Firefox
        window.sidebar.addPanel(document.title, window.location.href, "");
      } else if (window.external && "AddFavorite" in window.external) {
        // IE
        window.external.AddFavorite(location.href, document.title);
      } else if (window.opera && window.print) {
        // Opera
        this.bookmark = true;
        this.$message.success("请按 Ctrl+D 手动添加书签");
      } else {
        // Other
        this.$message.success("请按 Ctrl+D 手动添加书签");
      }
    },
    refreshTopic() {
      if (this.isRefreshing) {
        return;
      }
      this.isRefreshing = true;
      chatSampleService.list().then((data) => {
        this.topicList = shuffleArray(data.records);
        setTimeout(() => {
          this.isRefreshing = false;
        }, 800);
      });
    },
    refreshHistoryMap() {
      // 设置本地存储
      let historyMapStr = localStorage.getItem("historyMap");
      if (historyMapStr) {
        try {
          let retrievedMap = new Map();
          historyMapStr.split("_ai_split_").forEach((pair) => {
            let [key, value] = pair.split("_ai_key_");
            retrievedMap.set(key, JSON.parse(value));
          });
          this.historyMap = retrievedMap;
        } catch (err) {
          console.log(err);
          localStorage.removeItem("historyMap");
          this.historyMap = new Map();
        }
      } else {
        this.historyMap = new Map();
      }
      this.$refs.history.setHistoryMap(this.historyMap);
    },
    setHistoryMap() {
      if (this.talkList.length == 0) {
        return;
      }
      this.historyMap.set(
        this.historyId,
        JSON.parse(JSON.stringify(this.talkList))
      );

      // this.$set(this.historyMap,  this.historyId,  JSON.parse(JSON.stringify(this.talkList)));
      this.$refs.history.setHistoryMap(this.historyMap);

      let historyMapStr = Array.from(this.historyMap)
        .map(([key, value]) => `${key}_ai_key_${JSON.stringify(value)}`)
        .join("_ai_split_");
      localStorage.setItem("historyMap", historyMapStr);
    },
    newPrompt() {
      if (this.isInput) {
        this.stopCreate();
      }
      setTimeout(() => {
        this.clearTalkList();
      }, 100);
    },
    clearTalkList() {
      this.talkList = [];
      this.historyId = uuid();
    },
    setDetail(key, value) {
      if (this.isInput) {
        this.stopCreate();
      }
      setTimeout(() => {
        // alert(value)
        this.historyId = key;
        this.talkList = JSON.parse(value);
      }, 200);
    },
    setModelType(type) {
      if (this.isInput) {
        this.$message.info("机器人回复中，请机器人回复结束再切换模型!");
        return;
      }
      this.modelType = type;
      this.stopCreate();
      this.clearTalkList();
    },
    copyText(text) {
      const textToCopy = text;
      const textArea = document.createElement("textarea");
      textArea.style.position = "fixed";
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = 0;
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "成功复制文本" : "复制文本失败";
        this.$message.success(msg); // 可选：弹窗提示复制成功
      } catch (err) {
        this.$message.error(err.message);
      }
      document.body.removeChild(textArea);
    },
    deleteText(index) {
      this.$confirm(`确认要删除这条提问吗?会同时删除该提问对应的回复`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.talkList.splice(index, 1);
        this.talkList.splice(index, 1);
      });
    },
    reCreate() {
      this.talkList.pop();
      this.content = this.talkList.pop().content;
      this.sendMessage();
    },
    continueWrite() {
      this.content = "继续输出";
      this.sendMessage();
    },
    printStringOverTime(str) {
      let index = 0;
      this.isInput = true;
      const intervalId = setInterval(() => {
        if (index < str.length && this.isInput) {
          this.message = this.message + str[index];
          this.talkList.forEach((talk) => {
            if (talk.id === "r" + this.time) {
              talk.content = this.message;
            }
          });
          index++;
          this.$nextTick(() => {
            const container = this.$refs.scrollContainer;
            if (container) {
              container.scrollTop += 100; // 滚动量根据需要调整
            }
          });
        } else {
          // 清除定时器，避免继续执行
          clearInterval(intervalId);
          this.isInput = false;
          this.setHistoryMap();
        }
      }, 10);
    },
    sendTopic(topic) {
      if (!getToken()) {
        this.$refs.loginPopup.init();
        return;
      }
      if (topic.answer) {
        this.time = new Date().getTime();
        // 将当前发送信息 添加到消息列表。
        this.talkList.push(
          {
            id: "s" + this.time,
            role: "user",
            content: topic.content,
            type: 1,
          },
          {
            id: "r" + this.time,
            role: "assistant",
            modelType: this.modelType,
            content: "",
            type: 0,
          }
        );

        this.message = "";
        this.printStringOverTime(topic.answer);
      } else {
        this.content = topic.content;
        this.sendMessage();
      }
    },
    stopCreate() {
      if (this.socket) {
        this.socket.close();
      }

      this.isInput = false;
      if (getToken()) {
        this.createSocket();
      }
    },
    createSocket() {
      // 根据环境的不同，变更url
      const url = process.env.VUE_APP_WS_URL;
      // const url = "wss://www.awzs.work/wss-websocket-api"
      this.socket = new WebSocket(
        `${url}/front/websocket/${this.uuid}/${getToken()}/${this.modelType}`
      ); // 连接服务端
      //收到消息事件
      this.socket.addEventListener("message", (event) => {
        this.isInput = true;
        // console.log(event);
        if (event.data === "noLogin") {
          this.$refs.loginPopup.init();
          return;
        } else if (event.data == "needVip") {
          this.$alert("需要开通VIP才可以使用该功能!");
          return;
        } else if (event.data == "vipExpired") {
          this.$alert("VIP已经过期，请续费!");
          return;
        } else if (event.data == "noScoreEnough") {
          this.$alert("积分不足，请充值!");
          return;
        } else if (event.data == "overNum") {
          this.$alert("今天访问次数超过300,访问次数过于频繁，请明天再来!");
          return;
        }
        this.handleCallback(event);
      });

      //连接关闭事件
      this.socket.onclose = (e) => {
        this.isInput = false;

        console.log(
          "websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
        );
        console.log("Socket已关闭");
        this.handleCallback(e);
      };
      //发生了错误事件
      this.socket.onerror = (event) => {
        this.isInput = false;
        this.$message.error("服务异常请刷新重试！!");
        this.handleCallback(event);
      };
    },
    sendMessage() {
      if (!getToken()) {
        this.$refs.loginPopup.init();
        return;
      }
      if (!this.content.trim()) {
        this.content = "";
        return;
      }
      if (this.isInput) {
        this.$message.info("请等机器人回复后再发送!");
        return;
      }
      // this.content = prompt || this.content;
      // console.log(this.content)
      let query = [];
      this.talkList.forEach((item) => {
        if (item.type === 1) {
          query.push({
            role: "user",
            content: item.content,
          });
        } else {
          query.push({
            role: "assistant",
            content: item.content,
          });
        }
      });
      query.push({
        role: "user",
        content: this.content,
      });

      this.time = new Date().getTime() + Math.floor(Math.random() * 101);
      // 将当前发送信息 添加到消息列表。
      this.talkList.push(
        {
          id: "s" + this.time,
          role: "user",
          content: this.content,
          type: 1,
        },
        {
          id: "r" + this.time,
          role: "assistant",
          modelType: this.modelType,
          content: "",
          type: 0,
        }
      );
      this.content = "";

      this.$nextTick(() => {
        // 清空内容框中的内容
        const container = this.$refs.scrollContainer;
        if (container) {
          container.scrollTop += 100; // 滚动量根据需要调整
        }
      });
      // if (query.length > 5) {
      //   query = query.splice(query.length - 5, query.length);
      // }
      this.isInput = true;
      if (this.socket.readyState === WebSocket.CLOSED) {
        this.createSocket();
        this.socket.addEventListener("open", () => {
          this.socket.send(JSON.stringify(query));
          // 处理打开事件
        });
      } else {
        this.socket.send(JSON.stringify(query));
      }
      this.message = "";
    },
    beforeDestroy() {
      this.isInput = false;
      if (this.socket) {
        this.socket.close();
      }
    },
    handleCallback(event) {
      if (event.type == "error") {
        this.talkList.forEach((talk) => {
          if (talk.id === "r" + this.time) {
            talk.content = "系统出错了请重试!";
          }
        });
        this.setHistoryMap();
        this.isInput = false;
        return;
      }
      if (event.type == "close" || event.data == "[DONE]") {
        this.setHistoryMap();
        this.isInput = false;

        // var button = document.getElementById('btn');
        // button.addEventListener("click", function () {
        //   alert("按钮被点击了！");
        // });
        return;
      }
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data.content) {
          this.message = this.message + data.content;
          this.talkList.forEach((talk) => {
            if (talk.id === "r" + this.time) {
              talk.content = this.message;
            }
          });
          this.$nextTick(() => {
            const container = this.$refs.scrollContainer;
            if (container) {
              container.scrollTop += 100; // 滚动量根据需要调整
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less">
@import "~highlight.js/styles/github.css";
.pre-code-box {
  .pre-code-header {
    align-items: center;
    background: #e3e8f6;
    border-radius: 7px 7px 0 0;
    display: flex;
    height: 34px;
    .code-block-header__lang {
      color: #120649;
      flex: 1 0 auto;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      padding-left: 14px;
      text-align: justify;
    }
    .code-block-header__copy {
      align-items: center;
      color: #7886a4;
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 14px;
      text-align: justify;
      user-select: none;
      padding: 0 10px;
    }
  }
  .pre-code {
    background-color: #eef0f7;
    font-family: Consolas, Monaco, monospace;
    line-height: 24px;
    overflow: auto;
    padding: 14px;
    .hljs {
      background-color: #eef0f7;
    }
  }
}
.commit {
  background: transparent;
  background-color: linear-gradient(90deg, #2e6ee7, #8c8ef7);
  bottom: 29px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 29px;
  transition: all 0.16s;
  width: 60px;
}
.commit:hover {
  height: 44px;
  width: 66px;
  bottom: 27px;
  right: 26px;
  background-color: linear-gradient(90deg, #81a8f1, #adaff5) !important;
  opacity: 0.8;
}
.el-link.el-link--primary {
  color: #4a55f5;
}
.el-link.el-link--primary:hover {
  color: #717af0;
}

.commit > svg {
  pointer-events: none;
  transform: scale(1.8) !important;
}
.center {
  display: flex;
  height: 100%;
}

.p-center {
  background: #f6f7f9;
  background-size: cover;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  min-height: 600px;
}
.p-content {
  padding-bottom: 20px;
  padding-top: 90px;
  max-width: 1100px;
  padding: 30px 82px 0;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  .main {
    display: flex;
    margin-top: 30px;
    height: 100%;

    .m-center {
      height: calc(100% - 240px);
      overflow-y: auto;
      width: 100%;
      .m-block {
        display: flex;
        justify-content: center;
        width: 100%;
        .m-logo {
          top: 0;
          align-items: center;
          background: linear-gradient(-41deg, #1d6ff5, #9a7ffc);
          &.gpt4 {
            background: linear-gradient(-41deg, #ff5722, #ffc107);
          }
          &.gpt4t {
            background: linear-gradient(-41deg, #0d769d, #61b9df);
          }
          &.gpt4o {
            background: linear-gradient(270deg, #d90733, #ed7f91);
          }
          &.gpt4oMini {
            background: linear-gradient(270deg, #0e8105, #12e691);
          }
          border-radius: 5px;
          padding: 5px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          overflow: hidden;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
        .m-logo-img {
          top: 0;
          align-items: center;
          border-radius: 5px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          overflow: hidden;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
          img {
            background: transparent !important;
            border-radius: 5px;
            padding: 0px;
            width: 100%;
            height: 100%;
          }
        }
        .m-content {
          &.user {
            background: transparent !important;
            font-size: 15px;
            line-height: 30px;
            font-weight: 600;
            color: #333;
          }
          &.ai .content,
          &.help {
            background: hsla(0, 0%, 100%, 0.9);
            border-radius: 8px;
            box-shadow: 0 16px 20px 0 rgba(174, 167, 223, 0.06);
            padding: 20px;
            color: #555;
          }
          .m-action {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px;
          }
          margin-left: 10px;
          width: calc(100% - 40px);
          margin-bottom: 25px;

          position: relative;
          text-align: left;

          .topic-card {
            background: #f6f8fd;
            border-radius: 6px;
            cursor: pointer;
            margin-bottom: 8px;
            padding: 16px;
            width: calc(50% - 4px);
          }

          .topic-card:nth-of-type(odd) {
            margin-right: 8px;
          }

          .topic-card:last-of-type,
          .topic-card:nth-last-of-type(-n + 2):not(:nth-child(2n)) {
            margin-bottom: 0;
          }

          .topic-card:not(.Fk0LgC35):hover {
            background: #e9ecfb;
          }

          .topic-card:not(.Fk0LgC35):hover .topic-center,
          .topic-card:not(.Fk0LgC35):hover .topic-icon .topic-icon-text {
            color: #4955f5;
          }

          .topic-card .topic-content {
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            color: #676c90;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: -o-ellipsis-lastline;
            text-overflow: ellipsis;
          }

          .topic-card .topic-icon {
            align-items: center;
            display: flex;
            margin-bottom: 6px;
          }

          .topic-card .topic-icon .topic-icon-img {
            height: 21px;
            margin-right: 8px;
            width: 21px;
          }

          .topic-card .topic-icon .topic-icon-text {
            color: #120649;
            font-family: PingFangSC-Medium;
            font-size: 21px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }

          .topic-card .topic-center {
            color: #676c90;
            font-family: PingFang-SC-Regular;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;
            word-break: break-all;
          }

          .topic-card.Fk0LgC35 {
            cursor: not-allowed;
          }
          .text-action .topic-panel {
            display: flex;
            flex-wrap: wrap;
          }
          .text-action {
            border-radius: 0 0 8px 8px;
            display: flex;
            flex-direction: column;
            margin-top: 0.16rem;
          }
          .text-top {
            display: flex;
            flex-direction: column;
          }

          .text-top strong {
            color: #05073b;
            font-family: PingFangSC-Semibold;
            font-size: 26px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 36px;
            margin-bottom: 0.1rem;
            text-align: left;
          }

          .text-top span {
            color: #676c90;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 26px;
            text-align: justify;
            margin: 20px 0;
          }

          .text-top a {
            color: #4955f5;
            font-weight: 700;
          }

          .text-top a:hover {
            color: #2c38db;
          }

          .text-action {
            border-radius: 0 0 8px 8px;
            display: flex;
            flex-direction: column;
            margin-top: 0.16rem;
          }

          .text-action .M5fGI_9E {
            color: #4955f5;
            font-family: PingFangSC-Semibold;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 7px;
          }

          .text-action .YGsZqRDA {
            color: #676c90;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;
            text-align: justify;
          }

          .text-action .text-tip {
            color: #6d6790;
            font-size: 14px;
            justify-content: space-between;
            line-height: 26px;
            margin-bottom: 10px;
          }

          .text-action .text-tip,
          .text-action .text-tip .text-right {
            align-items: center;
            display: flex;
          }

          .text-action .text-tip .text-right .text-btn {
            align-items: center;
            color: #4955f5;
            cursor: pointer;
            display: flex;
          }

          .text-action .text-tip .text-right .text-btn {
            &:hover {
              font-weight: 500;
            }
            .refresh_anim {
              animation-name: refresh;
              animation-duration: 0.5s;
              animation-fill-mode: forwards;
              animation-timing-function: linear;
            }

            @keyframes refresh {
              0% {
                transform: rotate(0deg);
              }
              50% {
                transform: rotate(-180deg);
              }
              100% {
                transform: rotate(-180deg);
              }
            }
          }

          .text-action .text-tip .text-right .text-btn .text-refresh {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            margin-right: 4px;
            width: 20px;
          }

          .text-action .text-tip .text-right .text-btn .text-refresh > svg {
            transform: none !important;
          }

          .text-action .text-tip .text-right .text-split {
            background: #cfccdf;
            height: 10px;
            margin: 0 12px;
            opacity: 0.7;
            width: 1px;
          }

          .text-action .text-tip .text-right .text-more {
            align-items: center;
            color: #4955f5;
            cursor: pointer;
            display: flex;
          }

          .text-action .text-tip .text-right .text-more:hover {
            font-weight: 500;
          }

          .text-action .text-tip .text-right .text-more .text-btn-more {
            align-items: center;
            display: flex;
            height: 16px;
            width: 16px;
          }

          .text-action .topic-panel {
            display: flex;
            flex-wrap: wrap;
          }

          .text-top {
            display: flex;
            flex-direction: column;
            strong {
              color: #05073b;
              font-family: PingFangSC-Semibold;
              font-size: 26px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 36px;
              margin-bottom: 0.1rem;
            }
            span {
              color: #676c90;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: 26px;
              text-align: justify;
            }
          }
          .text-action {
            border-radius: 0 0 8px 8px;
            display: flex;
            flex-direction: column;
            margin-top: 0.16rem;
            .text-tip {
              color: #6d6790;
              font-size: 14px;
              justify-content: space-between;
              line-height: 26px;
              margin-bottom: 10px;
              align-items: center;
              display: flex;
            }
          }
        }
      }
    }
    .m-bottom {
      padding: 0 20px 20px 20px;
      position: absolute;
      // margin-left: 16px;
      width: calc(100% - 140px);
      background: #f6f7f9;
      bottom: 10px;
      display: grid;
      align-items: center; /* 垂直居中对齐 */
      // justify-items: center; /* 水平居中对齐 */
      z-index: 10;
      .tip {
        color: #666;
        font-size: 13px;
        margin-bottom: 10px;
        float: right;
        text-align: right;
      }
      .el-textarea__inner {
        border-radius: 10px;
      }
      .el-textarea__inner:focus {
        outline: 0;
        border-color: #4a55f5 !important;
      }
    }
  }
}
.radio-group {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.03);
  display: inline-flex;
  height: 40px;
  margin: auto;
  padding: 3px;
  // position: absolute;
  top: 26px;
  z-index: 10;
}

.radio-group .radio-label {
  z-index: 2;
}

.radio-group > div {
  border-radius: 20px;
  color: #9798ae;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  line-height: 34px;
  padding: 0 20px;
  text-align: center;
}

.radio-group > div:hover {
  cursor: pointer;
}

.radio-group .radio-button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.radio-group .radio-button:hover {
  color: #4955f5;
}

.radio-group .radio-button-21 {
  align-items: center;
  display: flex;
  justify-content: center;
}

.radio-group .radio-button-21 > span {
  margin-left: 4px;
}

@media screen and (max-width: 799px) {
  .radio-group .radio-button-21 > span {
    margin-left: 0.04rem;
  }
}

.radio-group .radio-button-21 .radio-button-vip {
  height: 20px;
  padding: 1px 0;
  width: 18px;
}

.radio-group .radio-button-31 .radio-button-vip {
  height: 20px;
  padding: 1px 0;
  width: 18px;
}

.radio-group .radio-button-41 .radio-button-vip {
  height: 20px;
  padding: 1px 0;
  width: 18px;
}
.radio-group .radio-button-51 .radio-button-vip {
  height: 20px;
  padding: 1px 0;
  width: 18px;
}

@media screen and (max-width: 799px) {
  .radio-group .radio-button-21 .radio-button-vip {
    height: 0.2rem;
    width: 0.18rem;
  }
}

.radio-group .radio-button-21 .pi6f441z {
  height: 20px;
  padding: 1px 0;
  width: 18px;
}

@media screen and (max-width: 799px) {
  .radio-group .radio-button-21 .pi6f441z {
    height: 0.2rem;
    width: 0.18rem;
  }
}

.radio-group .radio-button-21 .radio-button-text,
.radio-group .radio-button-21:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(270deg, #81410d, #bc6511);
  -webkit-background-clip: text;
}

.radio-group .radio-button-21.radio-button-22:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(0deg, #dd7411, #58290a);
  -webkit-background-clip: text;
}

.radio-group .radio-button-31 .radio-button-text,
.radio-group .radio-button-31:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(270deg, #810d24, #bc112d);
  -webkit-background-clip: text;
}

.radio-group .radio-button-31.radio-button-32:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(0deg, #dd116d, #de0b0b);
  -webkit-background-clip: text;
}
.radio-group .radio-button-33 {
  background: 100% / contain no-repeat
      url(https://nlp-eb.cdn.bcebos.com/static/eb/asset/vip-background.937cdbb2.png),
    linear-gradient(133deg, #d8111123, #ae0f1728);
}
.radio-group .radio-button-41 .radio-button-text,
.radio-group .radio-button-41:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(270deg, #22bd17, #0c774c);
  -webkit-background-clip: text;
}
.radio-group .radio-button-41.radio-button-42:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(270deg, #22bd17, #0c774c);
  -webkit-background-clip: text;
}
.radio-group .radio-button-43 {
  background: 100% / contain no-repeat
      url(https://nlp-eb.cdn.bcebos.com/static/eb/asset/vip-background.937cdbb2.png),
    linear-gradient(133deg, #acdaa920, #6cc3a024);
}
.radio-group .radio-button-51.radio-button-52:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(0deg, #2196f3, #2196f3);
  -webkit-background-clip: text;
}
.radio-group .radio-button-53 {
  background: 100% / contain no-repeat
      url(https://nlp-eb.cdn.bcebos.com/static/eb/asset/vip-background.937cdbb2.png),
    linear-gradient(133deg, #2195f31d, #2195f33e);
}

.radio-group .radio-button-51 .radio-button-text,
.radio-group .radio-button-51:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(270deg, #2196f3, #2196f3);
  -webkit-background-clip: text;
}

.radio-group .radio-button-51.radio-button-52:hover span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(0deg, #2196f3, #2196f3);
  -webkit-background-clip: text;
}
.radio-group .radio-button-53 {
  background: 100% / contain no-repeat
      url(https://nlp-eb.cdn.bcebos.com/static/eb/asset/vip-background.937cdbb2.png),
    linear-gradient(133deg, #1be68127, #1be68127);
}
.radio-group .radio-button-23 {
  background: 100% / contain no-repeat
      url(https://nlp-eb.cdn.bcebos.com/static/eb/asset/vip-background.937cdbb2.png),
    linear-gradient(133deg, #fff9e4, #fff9e4);
}

.radio-group .radio-button-11 {
  background-image: linear-gradient(
    133deg,
    rgba(221, 136, 255, 0.1),
    rgba(27, 68, 226, 0.1)
  );
  color: #4955f5;
}

.radio-group .E0kNPs9M {
  align-items: center;
  display: flex;
  position: relative;
}

.radio-group .E0kNPs9M:hover {
  color: #7471f9;
}

.radio-group .E0kNPs9M .O4wT8TVi {
  font-weight: 400;
  margin-right: 3px;
  padding-top: 1px;
}

.radio-group .E0kNPs9M .x1sq3IEg {
  margin-left: 4px;
  width: 30px;
}

.radio-group .E0kNPs9M .OZBXH2SL {
  display: inline-flex;
  height: 20px;
  position: absolute;
  right: -12px;
  width: 20px;
}

.radio-group .E0kNPs9M .ATjvLgh3 {
  background-image: linear-gradient(
    90deg,
    rgba(73, 85, 245, 0.7),
    rgba(73, 85, 245, 0.7),
    transparent 50%
  );
  background-repeat: repeat-x;
  background-size: 2px 1px;
  height: 1px;
  position: absolute;
  right: -27px;
  width: 25px;
}

@media screen and (max-width: 799px) {
  .radio-group {
    justify-content: space-between;
    left: 50%;
    margin-top: 0.7rem;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    width: 3.2rem;
  }

  .radio-group .pijiLiX9 {
    height: 16px;
    margin-left: 0.04rem;
    vertical-align: sub;
    width: 16px;
  }
}

@media screen and (max-width: 799px) and (max-width: 799px) {
  .radio-group .pijiLiX9 {
    height: 0.16rem;
    width: 0.16rem;
  }
}

@media screen and (max-width: 799px) {
  .radio-group .radio-button {
    padding: 0 0.17rem;
  }

  .radio-group .radio-button-21 {
    padding: 0 0.08rem 0 0.07rem;
  }

  .radio-group .radio-button-21:hover span:not(.radio-button-text) {
    -webkit-text-fill-color: inherit;
    background: none;
    -webkit-background-clip: unset;
  }
}
</style>
